<template>
  <ListFrameTeaching :immediateInit="false"
    :viewConf="{ showPager: true, showPublishingHouse: true, selectAble: true, showReset: true }" :getPageFn="getPage"
    keep-status>

    <template slot-scope="{item,index,pageList,requestFn}">
      <MaterialBookItem @editBook="editBook(item)" @on-del="requestFn" @on-addVolum="onaddVolum" :info="item" />
      <Divider v-if="index !== pageList.length - 1" style="background:#ccc" />
    </template>


    <template v-slot:conditions="{ requestFn }">
      <div class="flex1" style="text-align:right;">
        <Button v-if="isQuery" class="m-r-md" type="primary" @click="doQuery(requestFn)">查询</Button>
        <Button @click="editingBook = null; showCategoryForm = true">新增书名</Button>
      </div>
      <Modal v-model="showCategoryForm" footer-hide>
        <MaterialBookForm v-if="showCategoryForm" :allPList="allPList" :pressObj="editingBook ? editingBook[0] : undefined"
          :gradeObj="editingBook ? editingBook[1] : undefined" :bookObj="editingBook ? editingBook[2] : undefined"
          style="padding-top:25px;padding-right:25px" @success="showCategoryForm = false; requestFn()"
          @cancel="showCategoryForm = false" />
      </Modal>

      <UnitsDrawer :volumns="volumns" />
    </template>
    <template slot="batch-opterate" slot-scope="{items,requestFn}">
      <Button @click="onshelfFn(items).then(() => requestFn(1))"
        :disabled="!items.length || items.some(ele => ele.status === 'up')">上架</Button>
      <Button @click="offshelfFn(items).then(() => requestFn(1))"
        :disabled="!items.length || items.some(ele => ele.status !== 'up')">下架</Button>
    </template>
  </ListFrameTeaching>
</template>

<script>
import MaterialBookForm from "./MaterialBookForm";
import { getCategoryList, shelfChange } from "./services/materialService";
import MaterialBookItem from "./MaterialBookItem";
import UnitsDrawer from "./UnitsDrawer";
export default {
  components: { MaterialBookForm, MaterialBookItem, UnitsDrawer },
  data() {
    return {
      showCategoryForm: false,
      volumns: [],
      editingBook: null,
      isQuery: true,
      allPList: [],
    };
  },
  created() {
    this.isQuery = true;
  },
  methods: {
    doQuery(requestFn) {
      this.isQuery = false;
      requestFn();
    },
    getPage() {
      return getCategoryList({ subject: this.$store.getters.getLaun }).then(
        rows => {
          this.allPList = rows;
          const grades = rows.filter(ele => "grade" === ele.levelCode);
          const press = rows.filter(ele => "press" === ele.levelCode);
          const volumes = rows.filter(ele => "material" === ele.levelCode);
          this.volumns = volumes;
          const units = rows.filter(ele => "unit" === ele.levelCode);
          const books = rows
            .filter(ele => "book" === ele.levelCode)
            .map(book => {
              let grade = grades.find(ele => ele.id === book.parentId);
              if (!grade) {
                console.error("存在异常数据：book:", book, "的年级对象不存在");
                return null;
              }
              let pres = press.find(ele => ele.id === grade.parentId);
              if (!grade) {
                console.error(
                  "存在异常数据：年级:",
                  grade,
                  "的出版社对象不存在"
                );
                return null;
              }
              const _volumes = volumes
                .filter(ele => ele.parentId === book.id)
                .sort((a, b) => a.sort - b.sort);
              _volumes.forEach(v => {
                v.childList = units
                  .filter(ele => ele.parentId === v.id)
                  .sort((a, b) => a.sort - b.sort);
              });
              return {
                "0": pres,
                "1": grade,
                "2": book,
                "3": _volumes,
                status: book.status,
                id: book.id
              };
            })
            .filter(ele => ele)
            .sort((a, b) => {
              let flag = (+a[0].sort || 0) - (+b[0].sort || 0);
              if (flag) {
                return flag;
              }
              flag = (+a[1].sort || 0) - (+b[1].sort || 0);
              if (flag) {
                return flag;
              }
              flag = (+a[2].sort || 0) - (+b[2].sort || 0);
              return flag;
            });

          return {
            rows: books,
            totalCount: books.length
          };
        }
      );
    },
    onaddVolum(volum) {
      this.volumns.push(volum);
    },
    editBook(item) {
      this.editingBook = JSON.parse(JSON.stringify(item));
      this.showCategoryForm = true;
    },
    onshelfFn(list) {
      const bookIds = list.map(infos => infos[2].id);
      const reqs = bookIds.map(id => shelfChange(id, "up"));
      return Promise.all(reqs);
    },
    offshelfFn(list) {
      const bookIds = list.map(infos => infos[2].id);
      const reqs = bookIds.map(id => shelfChange(id, "down"));
      return Promise.all(reqs);
    }
  }
};
</script>

<style></style>