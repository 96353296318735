<template>
  <Form :label-width="70">
    <template v-if="allList">
      <FormItem label="出版社">
        <MaterialCategorySelect
          style="width: 250px"
          placeholder="请输入出版社"
          type="press"
          :allOptions="allList"
          :current="pressObj"
          @update:current="onPressChange"
        />&nbsp;&nbsp;序号：
        <InputNumber placeholder="序号" v-model="pressObj.sort" />
      </FormItem>
      <FormItem label="年级">
        <MaterialCategorySelect
          style="width: 250px"
          placeholder="请输入年级"
          type="grade"
          :allOptions="allList"
          :current="gradeObj"
          @update:current="onGradeChange"
        />&nbsp;&nbsp;序号：
        <InputNumber placeholder="序号" v-model="gradeObj.sort" />
      </FormItem>
      <FormItem label="书名">
        <MaterialCategorySelect
          style="width: 250px"
          placeholder="请输入书名"
          type="book"
          :allOptions="allList"
          :current="bookObj"
          @update:current="onBookChange"
        />&nbsp;&nbsp;序号：
        <InputNumber placeholder="序号" v-model="bookObj.sort" />
      </FormItem>
    </template>
    <FormItem label="封面">
      <ImgSelect
        :imgHolders="[this.bookObj]"
        :config="{
          file: 'file',
          key: 'cover',
          url: 'coverUrl',
          hiddeSelectWhenFull: true,
          equalProportion: true,
        }"
        :compress="{ maxWidth: 300 }"
      />
    </FormItem>
    <FormItem style="text-align: right">
      <Button style="margin-right: 2em" @click="$emit('cancel')">取消</Button>
      <Button type="primary" @click="submit">确认</Button>
    </FormItem>
  </Form>
</template>

<script>
import { getCategoryList, saveCategory } from "./services/materialService";
import MaterialCategorySelect from "./MaterialCategorySelect";
export default {
  props: {
    allPList:[],
    pressObj: {
      default() {
        return {
          parentId: null,
          subject: this.$store.getters.getLaun,
          levelCode: "press",
          name: "",
          id: "",
          coverUrl: "",
          cover: "",
          sort: null,
        };
      },
    },
    gradeObj: {
      default() {
        return {
          parentId: "",
          subject: this.$store.getters.getLaun,
          levelCode: "grade",
          name: "",
          id: "",
          coverUrl: "",
          cover: "",
          sort: null,
        };
      },
    },
    bookObj: {
      default() {
        return {
          parentId: "",
          subject: this.$store.getters.getLaun,
          levelCode: "book",
          name: "",
          id: "",
          coverUrl: "",
          cover: "",
          sort: null,
        };
      },
    },
  },
  data() {
    return {
      isModify: false,
      allList: this.allPList,
    };
  },
  components: { MaterialCategorySelect },
  methods: {
    validate() {
      if (!this.pressObj.name) {
        return "未填写出版社！";
      }
      // if (!this.pressObj.sort) {
      //   return '未填写出版社序号';
      // }
      if (!this.gradeObj.name) {
        return "未填写年级！";
      }
      if (!this.gradeObj.sort) {
        return "未填写年级序号";
      }
      if (!this.bookObj.name) {
        return "未填写书名！";
      }
      // if (!this.bookObj.sort) {
      //   return '未填写书序号';
      // }
      if (!this.bookObj.cover && !this.bookObj.file) {
        return "未选择封面图片！";
      }
    },
    async submit() {
      let msg = this.validate();
      if (msg) {
        this.$Message.warning(msg);
        return;
      }
      if (this.bookObj.file) {
        let { key, url } = await this.$uploadService.uploadOne(
          this.bookObj.file
        );
        this.bookObj.cover = key;
        this.bookObj.coverUrl = url;
        this.bookObj.file = undefined;
      }
      let buf = Object.assign({}, this.pressObj, {
        childList: [
          Object.assign({}, this.gradeObj, { childList: this.bookObj }),
        ],
      });

      saveCategory([buf]).then(() => {
        this.$emit("success");
      });
    },
    onPressChange(press) {
      Object.assign(this.pressObj, press);
      if (!this.isModify) {
        this.gradeObj.parentId = this.pressObj.id;
        this.gradeObj.id = "";
        this.gradeObj.name = "";
        this.gradeObj.sort = null;
      }
    },
    onGradeChange(grade) {
      Object.assign(this.gradeObj, grade);
      if (!this.isModify) {
        this.bookObj.parentId = this.gradeObj.id;
        this.bookObj.id = "";
        this.bookObj.name = "";
        this.bookObj.sort = null;
      }
    },
    onBookChange(book) {
      // 书的变化，始终只取名称
      this.bookObj.name = book.name;
    },
  },
  beforeMount() {
    this.isModify = !!this.bookObj.id;
    // getCategoryList({ subject: this.$store.getters.getLaun })
    //   .then((list) => {
    //     this.allList = list || [];
    //   })
    //   .catch((err) => {
    //     this.$Message.warning("[后台错误]" + err);
    //   });
  },
};
</script>

<style>
</style>